:root {
  --primary-color: #3f88f8;
  --secondary-color: #2ecc71;
  --background-color: #282c34;
  --background-panel: #00000042;
  --text-color: #ffffff;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

a {
  text-decoration: none;
}
body {
  background-color: #fff;
  animation: fadeIn 1s ease-in-out;
  overflow: hidden;
}

p,
span,
h1,
h2,
h3,
h4,
h5 {
  color: black;
}

.text-primary {
  color: var(--text-color);
}

.text-white {
  color: var(--text-color);
}

.boder-right {
  border-right: 1px solid black;
}

.text-center {
  text-align: center;
}

.text-small {
  font-size: 12px;
}

.text-left {
  text-align: left;
}
.App {
  text-align: center;
  margin: 0 auto;
  animation: fadeIn 1s ease-in-out;
  height: 100vh;
  overflow: auto;
}

.limited-text {
  display: -webkit-box;
  -webkit-line-clamp: 10; /* Limitar a 6 líneas */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.limited-text-ther {
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Limitar a 6 líneas */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.btn-provider {
  display: flex;
  align-items: center;
  justify-content: center;
}

.social-login img {
  width: 20px;
  margin-right: 10px;
}

.google-login,
.facebook-login,
.telegram-login {
  background-color: #fff;
  border: none;
  padding: 0.7rem 1rem;
  cursor: pointer;
  flex: 1;
  border: 1px solid #eee;
  margin-bottom: 10px;
}

.google-login {
  margin-left: 0;
}

.facebook-login {
  margin-right: 0;
}

.filter-blur {
  filter: blur(3px);
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
