#speed-dating-schedulle .content-schedulle {
  text-align: left;
}
.availability-screen {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  padding: 5px;
  width: 90%;
  margin: 0 auto;
  overflow: scroll;
}

.content-slots,
.content-guest {
  padding-bottom: 100px;
  height: 80vh;

  margin-top: 20px;
  padding-top: 100px;
}

.content-guest {
}
.content-guest .content-input {
  width: 90%;
  margin: 0 auto;
}
.time-slot {
  border: 2px solid #000;
  border-radius: 15px;
  padding: 20px;
  text-align: center;
  font-family: Arial, sans-serif;
}

.time-slot h3 {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
}

.time-slot p {
  margin: 5px 0 0;
  font-size: 14px;
  color: #666;
}
