/* src/components/LoginPage.css */

.boton-naranja {
  width: 100%;
  height: 50px;
  background: #fdc97d;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 30px;
  color: rgba(0, 0, 0, 1);
  font-weight: 600;
  font-size: 18px;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.photoContainer {
  margin-top: 70px;
}

.disabled {
  background-color: #eee;
  opacity: 0.5;
}
