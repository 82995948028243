#speed-dating-invitation .content-invitations {
  text-align: left;
}

.invite-card {
  border: 1px solid #ddd;
  border-radius: 12px;
  padding: 10px;
  max-width: 400px;
  margin: 20px auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.invite-card-content {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
}

.invite-card-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 15px;
}

.invite-card-info {
  flex: 1;
  text-align: left;
}

.invite-card-name {
  font-size: 18px;
  font-weight: bold;
  margin-top: 0px;
  margin-bottom: 5px;
}

.invite-card-info p {
  margin-block: 1px;
  font-size: 11px;
}
.invite-card-actions {
  display: flex;
  justify-content: space-between;
}

.invite-card-accept {
  flex: 1;
  background-color: #6ee7bc;
  border: none;
  border-radius: 10px 0 0 10px;
  color: black;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
}

.invite-card-reject {
  flex: 1;
  background-color: #eb545d;
  border: none;
  border-radius: 0 10px 10px 0;
  color: white;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
}

.invite-card-accept:hover,
.invite-card-reject:hover {
  opacity: 0.9;
}
