.launch-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(rgba(64, 64, 64, 1), rgba(0, 0, 0, 1));
  padding-inline: 40px;
}

.launch-container p {
  width: 100%;
  text-align: center;
  line-height: 17px;
  font-size: 12px;
  font-weight: 500;
  color: white;
}
.logoLaunch {
  width: 100%;
}

.launch-btns {
  width: 100%;
}

.launch-container a{
  color:white !important;
}