.ripple-effect {
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
  animation: ripple-animation 0.6s linear;
  background-color: rgba(255, 255, 255, 0.4);
}

.button {
  position: relative;
  overflow: hidden;
  color: #fff;
  border: none;
  border-radius: 67px;
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
  font-size: 1rem;
  padding: 0.75em 1.5em;
}

.button.primary {
  background: linear-gradient(90deg, #f6b607 0%, #ee805f 100%);
}

.button.line {
  background: transparent;
  border: 1px solid white;
}

.button .label {
  font-size: 18px;
  font-size: inherit;
  font-weight: bold;
  text-transform: uppercase;
}

.button i {
  font-size: 1.25rem;
}

.button-xs {
  padding: 0.5em 1em;
  font-size: 0.875rem;
}

.button-md {
  padding: 0.75em 1.5em;
  font-size: 1rem;
}

.button-lg {
  padding: 1em 2em;
  font-size: 1.125rem;
}

.button-disabled {
  opacity: 0.5;
}

@keyframes ripple-animation {
  to {
    transform: scale(4);
    opacity: 0;
  }
}

/* Media Queries */
@media (max-width: 768px) {
  .button {
    font-size: 0.875rem;
    padding: 0.75em 1.25em;
  }

  .button-xs {
    padding: 0.5em 0.75em;
    font-size: 0.75rem;
  }

  .button-md {
    padding: 0.75em 1.25em;
    font-size: 0.875rem;
  }

  .button-lg {
    padding: 0.875em 1.75em;
    font-size: 1rem;
  }
}
