input {
  background-color: #fff;
  color: #000;
  border: none;
  padding-left: 20px;
  width: 100%;
  font-size: 16px;
  border-bottom: 1px solid black;
}

input.input-xs {
  height: 35px;
}

input.input-md {
  /*padding: 20px 0px; */
  height: 50px;
}

.label-input {
  font-size: 20px;
  display: block;
  margin-bottom: 10px;
  color: #ccc;
}

.content-input {
  width: 100%;
  position: relative;
  display: flex;
}

.content-input.disabled {
  opacity: 0.5;
}
.span-action {
  position: absolute;
  right: 20px;
  top: 34%;
}

@media (max-width: 600px) {
  input {
    font-size: 16px !important;
  }
}
