#speed-dating-shedulle-appointments .content-shedulle-appointments {
  text-align: left;
}

#speed-dating-shedulle-appointments {
  overflow: scroll;
  padding-bottom: 200px;
  height: 80vh;
}

#speed-dating-shedulle-appointments .invite-card-reject {
  flex: 1;
  background-color: #eb545d;
  border: none;
  border-radius: 10px;
  color: white;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
}
