#layout-title img {
  width: 30px;
  height: 30px;
  margin-right: 5px;
  margin-top: -5px;
}

#layout-title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

#isotipo-layout {
  width: 91px;
}
.child-component {
  padding-inline: 200px;
  padding-top: 10px;
}

.buttom-calendar {
  position: absolute;
  z-index: 9999;
  bottom: 100px;
  right: 20px;
  background: #ffc65c;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
}

.buttom-calendar i {
  font-size: 20px;
  color: black;
}
@media (max-width: 1200px) {
  .child-component {
    padding-inline: 20px;
  }
}

@media (max-width: 890px) {
  .child-component {
    padding-inline: 20px;
  }
}

@media (max-width: 670px) {
  .child-component {
    padding-inline: 20px;
  }
}
