#content-profile {
  padding-bottom: 200px;
}
#content-profile #photo_profile {
  width: 160px;
  height: 144px;
  border-radius: 20px;
  object-fit: cover;
  object-position: top;
}

#btn-save-profile-photo {
  width: 50%;
  margin: 0 auto;
}

#content-profile .option-profile {
  background-color: #d9d9d9;
  margin: 15px auto;
  padding: 15px 12px;
  text-align: left;
  font-size: 15px;
  border-radius: 10px;
}

#content-profile .option-logout {
  padding: 15px 12px;
  font-size: 15px;
  border-radius: 10px;
  color: red;
}

#settings-personal-data,
#settings-password,
#settings-preferences {
  padding-bottom: 200px;
}
