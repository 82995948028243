.splashContainer{
    width: 100%;
    height: 100vh;
    background: linear-gradient(rgba(64, 64, 64, 1), rgba(0, 0, 0, 1));
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logoSplash{
    width: 300px;
}
