#dating-invitation {
  overflow: scroll;
  padding-bottom: 200px;
}
.speed-dating-screen {
  padding: 10px;
  font-family: Arial, sans-serif;
  text-align: left;
}

.speed-dating-screen p {
  font-size: 13px;
}

.speed-dating-screen strong{
  font-size: 13px;
}


h1 {
  font-size: 24px;
}

p {
  font-size: 16px;
  margin-bottom: 15px;
}

strong {
  font-size: 16px;
}

.info-container {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

.info-box {
  border: 2px solid #000;
  border-radius: 10px;
  padding: 5px;
  width: 35%;
  height: 130px;
  text-align: center;
}

.info-box2 {
  border: 2px solid #000;
  border-radius: 10px;
  padding: 5px;
  width: 55%;
  height: 130px;
  text-align: center;
}

.info-box2 p{
margin: 5px;
}


.info-box p {
  margin: 0;
}

.circle-number {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #f0b34d;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: black;
  font-weight: 700;
  margin: 0 auto 10px;
}

.circle-number.green {
  background-color: transparent;
  width: 40px;
  height: 40px;
}

.invitation-button {
  background-color: #f0b34d;
  color: #000;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 0px;
}

h2 {
  font-size: 18px;
  margin: 20px 0;
}

.time-slot h3 {
  font-size: 13px !important;
}
.time-slot p {
  font-size: 13px !important;
}

.time-slot{
  padding: 10px;
}
.select-box {
  margin-bottom: 15px;

  padding: 10px;
  border: 2px solid #000;
  border-radius: 10px;
}

.schedule-button {
  background-color: #f0b34d;
  color: #000;
  padding: 15px;
  width: 100%;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  cursor: pointer;
}

.modal-guest .availability-screen {
  height: 600px;

  overflow: scroll;
}

.modal-guest .content-card-entrepreneur {
  width: 100%;
}

.content-slots, .content-guest {
  height: auto !important;

}