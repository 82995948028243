/* src/components/LoginPage.css */

.login-container {
  display: flex;
  height: 100vh;
  background-color: white;
  padding-inline: 40px;
}
.login-container a {
  color: var(--primary-color);
}
.login-container p {
  color: #828693;
  font-size: 13px;
  line-height: 16px;
}

form {
  display: flex;
  flex-direction: column;
}

.loadingAuth {
  position: absolute;
  background: #fdfdfdc2;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}
