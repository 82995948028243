#matches .math-item {
  background-color: #fff;
  margin: 15px auto;
  padding: 15px 12px;
  text-align: left;
  font-size: 15px;
  border-radius: 10px;
  border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
#matches .math-item i{
  color: black;
  font-size: 20px;
  margin-left: 0px;
  background: hsla(39, 100%, 68%, 1);
  padding: 10px;
  border-radius: 50px;
}

i.fab.fa-whatsapp.whatsapp {
  background: green !important;
  color: white !important;
}
