.bottom-navigation-content {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 80%;
}

.bottom-navigation {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 10px;
  max-width: 100%;
  margin: 0 auto;
  background-color: white;
  padding-bottom: 20px;
}

.bottom-navigation p {
  font-size: 12px;
  margin: 0px;
  margin-top: 10px;
}
.nav-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #7d848f;
  cursor: pointer;
  font-size: 25px;
}

.nav-item span {
  margin-top: 4px;
  font-size: 12px;
}

.activaitemnav,
.activaitemnav span {
  color: #fdc97d;
}

@media (max-width: 1200px) {
  .bottom-navigation-content {
    width: 75%;
  }
}

@media (max-width: 890px) {
  .bottom-navigation-content {
    width: 65%;
  }
}

@media (max-width: 670px) {
  .bottom-navigation-content {
    width: 100%;
  }
}
