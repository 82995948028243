.swipe-container {
  position: relative;
  width: 100%;
  height: 80vh;
  margin: auto;
}

.swipe {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: normal;
  justify-content: center;
  will-change: transform;
}

.card {
  background-color: white;
  width: 250px;
  height: 350px;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.card-people img {
  object-fit: cover;
  border-radius: 10px;
  width: 100%;
  height: 100%;
}

.card-people p {
  color: white;
  font-size: 14px;
  margin-bottom: 0px;
}

.card-people i {
  color: white;
  font-size: 14px;
  margin-bottom: -10px;
}
.card-people h4 {
  text-align: left;
  color: white;
  font-weight: bold;
  font-size: 20px;
  margin: 4px 0px;
}

.card-people {
  position: relative;
  width: 90%;
  height: 65%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 20px;
}
.card-people-caption {
  position: absolute;
  bottom: 10px;
  padding: 10px;
  color: white;
  font-size: 20px;
  z-index: 9999;
}

.card-people .overlay {
  background-color: rgba(0, 0, 0, 0.345);
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

.maching-people-buttons {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  position: absolute;
  bottom: 20%;
}

.maching-people-button {
  background: #20e020;
  width: 35%;
  color: white;
  padding: 10px;
  border-radius: 100px;
  height: 50px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 20px;
  cursor: pointer;
}

.maching-people-buttons .close {
  background: #ff0062 !important;
}

.item-summary-profile p {
  text-align: left;
}

body {
  font-size: 14px;
}
.container {
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(64, 64, 64, 1), rgba(0, 0, 0, 1));
}
.logo {
  width: 149px;
  height: 50px;
  background: url('../../assets/logos/logo.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 63px;
  left: 50%;
  transform: translateX(-50%);
  margin: 0;
  overflow: hidden;
}
.centered-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; /* Asegura que todo esté en una sola columna */
}

.back {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 70px;
  left: 23px;
  background-size: contain !important;
  background: url('../../assets/logos/atras.png');
}

.text span {
  color: rgba(255, 255, 255, 1);
  text-align: center;
  font-size: 24px;
}
.boton {
  width: 80%;
  height: 50px;
  background: rgba(110, 231, 188, 1);
  border-radius: 10px;
  overflow: hidden;
  margin-top: 30px;
  color: rgba(0, 0, 0, 1);
  font-weight: 600;
  font-size: 18px;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-row {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 40px;
}

.image {
  width: 150px;
  height: 150px;
  object-fit: cover;
  object-position: top;
  border-radius: 50%;
}

.image-large {
  width: 130px;
  height: 100px;
  object-fit: cover;
  margin-bottom: -150px;
  z-index: 9;
}

.text {
  text-align: center;
}

.maching-people-buttons span{
  color: white !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-size: 18px;
  font-weight: 800;
  margin-left: 10px;
}