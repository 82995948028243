/* src/components/LoginPage.css */

.direcotorie-profile-container {
  height: 100vh;
  background-color: white;
  text-align: left;
  padding-bottom: 200px;
}
.direcotorie-profile-container a {
  color: var(--primary-color);
}
.direcotorie-profile-container p {
  color: #828693;
  font-size: 13px;
  line-height: 16px;
}

.direcotorie-profile-container .photo-profile {
  position: relative;
}
.direcotorie-profile-container .photo-profile img {
  width: 100%;
  border-radius: 10px;
  filter: brightness(0.8);
}

.entrepreneur-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.content-card-entrepreneur {
  position: relative;
  width: 48%;
  height: 177px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.entrepreneur-caption-profile {
  width: 100%;
  position: absolute;
  bottom: 0px;
  text-align: left;
  padding: 20px;
  font-size: 11px;
  box-sizing: border-box;
  color: white;
  border-radius: 0 0 10px 10px;
}

.entrepreneur-caption-profile p {
  color: white;
  line-height: 14px;
  font-weight: 600;
}

.entrepreneur-caption-profile p.title {
  font-weight: bold;
  font-size: 28px;
  line-height: 29px;
}
.entrepreneur-caption-profile p.rol {
  font-size: 20px;
}

.direcotorie-profile-container h2 {
  margin-block: 9px;
}
.content-card-entrepreneur img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.item-summary-profile {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 300;
}

.item-summary-profile i {
  margin-right: 10px;
}
.item-summary-profile p {
  margin-block: 9px;
}

.direcotorie-profile-interest {
  display: flex;
  color: white;
  justify-content: space-between;
}

.direcotorie-profile-interest .interest-item {
  padding: 10px;
  width: 48%;
  box-sizing: border-box;
  border-radius: 10px;
}

.direcotorie-profile-interest .interest-item h2 {
  font-size: 21px;
  color: white;
}

.direcotorie-profile-interest .interest-item p {
  font-size: 12px;
  color: white;
}
.direcotorie-profile-interest .interest-what-offer {
  background-color: #2d4459;
}
.direcotorie-profile-interest .interest-what-looking {
  background-color: #fdc97d;
}

.direcotorie-profile-interest .interest-what-looking p,
.direcotorie-profile-interest .interest-what-looking h2 {
  color: black;
}
