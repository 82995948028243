/* src/components/LoginPage.css */

.direcotorie-container {
  height: 100vh;
  background-color: white;
  text-align: left;
}
.direcotorie-container a {
  color: var(--primary-color);
}
.direcotorie-container p {
  color: #828693;
  font-size: 13px;
  line-height: 16px;
}

.entrepreneur-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 200px;
}
.content-card-entrepreneur {
  position: relative;
  width: 48%;
  height: 177px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.entrepreneur-caption {
  background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  width: 100%;
  position: absolute;
  bottom: 0px;
  text-align: left;
  padding: 5px;
  font-size: 11px;
  box-sizing: border-box;
  color: white;
  border-radius: 0 0 10px 10px;
  padding-bottom: 10px;
}

.entrepreneur-caption p {
  margin: 0px;
  color: white;
  line-height: 14px;
}

.entrepreneur-caption p.title {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 5px;
}
.entrepreneur-caption p.rol {
  font-weight: bold;
  font-size: 10px;
  margin-bottom: 10px;
}

.content-card-entrepreneur img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  border-radius: 10px;
}

.filter-container {
  display: flex;
  overflow-x: scroll;
  white-space: nowrap;
  gap: 10px;
}

.filter-button {
  padding: 10px 20px;
  border: 2px solid #000;
  background-color: #fff;
  color: #000;
  border-radius: 10px;
  cursor: pointer;
  flex-shrink: 0;
  font-size: 15px;
}

.filter-button.active {
  background-color: #000;
  color: #fff;
}

.filter-container::-webkit-scrollbar {
  display: none;
}

.filter-ia-container {
  text-align: right;
  margin-bottom: 10px;
}

.filter-ia-button {
  padding: 10px 20px;
  background-color: hsla(39, 100%, 68%, 1);
  color: black;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  top: 40px;
  position: absolute;
  right: 20px;
}

.filter-ia-button:hover {
  padding: 10px 20px;
  background-color: hsla(39, 100%, 48%, 1);
  color: black;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  top: 40px;
  position: absolute;
  right: 20px;
}

.badge {
  top: 10px; /* Ajusta la posición vertical */
  right: 10px; /* Ajusta la posición horizontal */
  background: hsla(39, 100%, 68%, 1) !important;
  border-radius: 10px;
  padding: 0px 10px !important;
  color: black !important; /* Cambia el color del texto si es necesario */
  font-size: 12px; /* Ajusta el tamaño de la fuente si es necesario */
  z-index: 1; /* Asegura que el badge esté por encima de otros elementos */
}

.filter-container select {
  padding: 10px;
  border-color: #eee;
  width: 36%;
}

.fade-in {
  opacity: 0;
  animation: fadeIn 1s ease-in forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
