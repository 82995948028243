/* src/components/LoginPage.css */

.interest-container {
  height: 80vh;
  background-color: white;
  padding-inline: 40px;
}
.interest-container a {
  color: var(--primary-color);
}
.interest-container p {
  color: #828693;
  font-size: 13px;
  line-height: 16px;
}

form {
  display: flex;
  flex-direction: column;
}

.loadingAuth {
  position: absolute;
  background: #fdfdfdc2;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.badges-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  height: 80%;
  overflow: scroll;
}

.badges-container .badge-item {
  padding: 10px 15px;
  background-color: #f0f0f0;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.badges-container .badge-item.selected {
  background-color: hsla(39, 100%, 68%, 1);
  color: black;
}
